<template lang="pug">
    Dialog.dialogEditar(header="Editar agendamento" :visible.sync='show' :modal='true' @show="reset()")

        DialogCriarHorario(
            v-model="novoHorario.text"
            :visible.sync="showCriarHorario"
            @close="showCriarHorario = false"
            @input="onInputCriarHorario")

        ConfirmDialog(
            :display="showForce" :text="`${msgErro} Deseja salvar mesmo assim?`"
            @confirmed="handleSubmit(true)"
            @close="showForce = false")

        form(@submit.prevent='handleSubmit()')
            .ta-center(v-if='waiting')
                ProgressSpinner(strokeWidth='2')
            .p-grid.p-fluid.p-align-end(v-else)
                .p-col-12.p-md-2(style="align-self:start")
                    label.form-label Cód. guia:
                    InputText(type='text' v-model='model.cdGuiaEletronica' :disabled='true')
                .p-col-12.p-md-10(v-if='model.ie_tipo_agenda == "C"')
                    label.form-label Procedimento:
                    InputText(type='text' v-model='model.nm_procedimento_f' :disabled='true')
                .p-col-12.p-md-10(v-if='model.ie_tipo_agenda != "C"')
                    label.form-label Procedimento:
                    div(v-for="proc in model.cd_procedimentos" v-if="model.cd_procedimentos.length > 0")
                        InputText(type='text' :value="`${proc.cd_cbhbm} - ${proc.nm_procedimento}`" :disabled='true')
                    InputText(v-else type='text' v-model='model.nm_procedimento_f' :disabled='true')
                .p-col-12.p-md-9
                    label.form-label Paciente:
                    InputText(type='text' v-model='model.nm_paciente' :disabled='true')
                .p-col-12.p-md-3.ta-center
                    label.form-label Situação:
                    p.mtd-badge.co1 {{ model.ie_status_f }}

                p.col-12.p-grid.p-fluid.mt-1(v-if='model.ie_telemedicina && model.ie_tipo_agenda =="C"')
                    p.p-col-4 <b>Paciente Entrou:</b> {{  model.ie_paciente_entrou ? 'SIM' : 'NÃO' }}
                    p.p-col-4 <b>Médico Entrou:</b> {{  model.ie_medico_entrou ? 'SIM' : 'NÃO' }}
                    p.p-col-4 <b>Ligação:</b> {{ options.status_chamada[model.ie_status_chamada] }}

                .p-col-12.p-md-5(v-if='model.ie_tipo_agenda == "C"')
                    label.form-label Especialidade:
                    InputText(type='text' v-model='model.ds_especialidade' :disabled='true')
                .p-col-12.p-md-7(v-if='model.ie_tipo_agenda == "C"')
                    label.form-label Especialista:
                    InputText(type='text' v-model='model.ds_especialista' :disabled='true')
                .p-col-12(v-if='model.ie_tipo_agenda == "C"')
                    label.form-label Agenda:
                    ProgressBar(v-if='waitingAgendas' mode="indeterminate")
                    Dropdown(v-else v-model='dataSend.cd_agenda' :options='options.agendas' :disabled="model.ie_retorno"
                        optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione'  @change='getDatas()' )
                .p-col-12.p-md-4
                    label.form-label Data:
                    ProgressBar(v-if='waitingDatas' mode="indeterminate")
                    span(v-else-if="!dataSend.cd_agenda") Selecione a agenda.
                    span(v-else-if="!options.datas.length") Nenhum data disponível.
                    Dropdown(v-else v-model='dataSend.cd_data' :options='options.datas'
                        optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione' @change='getHorarios()')
                .p-col-12.p-md-4
                    label.form-label Horário:
                    ProgressBar(v-if='waitingHorarios' mode="indeterminate")
                    span(v-else-if="!dataSend.cd_data") Selecione a data.
                    span(v-else-if="!options.horarios.length") Nenhum horário disponível.
                    CustomDropdown(
                        v-model="dataSend.cd_horario"
                        :options="options.horarios"
                        optionDisabled="disabled"
                        optionLabel="text"
                        optionValue="value"
                        dataKey="value"
                        placeholder="Selecione"
                        footer-text="+ CRIAR HORÁRIO"
                        @footer-click="showCriarHorario = true"
                        v-else)
                .p-col-12.p-md-4
                    Button(icon='jam jam-save' label='Salvar'
                        :disabled="dataSend.cd_horario == null || model.id === dataSend.cd_horario")

</template>

<style lang="scss">
    .agendamento-listar {
        .dialogEditar {
            @media all and (min-width: 577px) {
                max-width: 640px;
                width: 100%;
            }
            .p-inputtext[disabled=disabled] {
                color: #000;
                font-weight: 600;
                background-color: #ddd;
            }
            .mtd-badge {
                margin-top: 6px;
                font-size: 14px;
                padding: 6px 12px;
            }
            .p-progress-spinner {
                width: 80px;
                margin: 40px auto;
            }
            .p-dialog-content {
                overflow: visible !important;
            }
            .dialog-confirm {
                width: 420px;
                max-width: 98%;
            }
        }
    }
</style>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import InputText from 'primevue/inputtext'
    import Dropdown from 'primevue/dropdown'
    import Button from 'primevue/button'
    import CustomDropdown from "@/components/CustomComponents/CustomDropdown"
    import DialogCriarHorario from "@/components/Agendamento/DialogCriarHorario"
    import ConfirmDialog from "@/components/CustomComponents/ConfirmDialog"

    import moment from 'moment'
    import { Agenda, Agendamento } from './../../middleware'
    export default {
        components: { Dialog, InputText, ProgressBar, ProgressSpinner,
            Dropdown, Button, CustomDropdown, DialogCriarHorario, ConfirmDialog },
        props: ['selectedRow', 'visible'],
        computed: {
            show: {
                get() { return this.visible; },
                set(val) { if (!val) this.$emit('close'); }
            }
        },
        data () {
            return {
                model: {},
                dataSend: {
                    cd_agenda: null,
                    cd_data: null,
                    cd_horario: null
                },
                options: {
                    agendas: [],
                    datas: [],
                    horarios: [],
                    status_chamada: {
                        "MC":"Médico Chamou",
                        "EA":"Em Andamento",
                        "PR":"Paciente Recusou",
                        "PS":"Paciente na Sala",
                        "DE":"Desligada"
                    }
                },
                showForce: false,
                msgErro: '',
                waiting: false,
                waitingAgendas: false,
                waitingDatas: false,
                waitingHorarios: false,
                modelDateDisabled: true,
                showCriarHorario: false,
                novoHorario: {
                    value: 0,
                    text: null,
                    disabled: false,
                    ie_encaixado: true,
                }
            }
        },
        methods: {
            reset() {
                if (this.selectedRow) {
                    this.model = Object.assign({}, this.selectedRow)
                    this.model.nm_procedimento_f = `${ this.model.cd_cbhpm } - ${ this.model.nm_procedimento }`
                    this.model.dt_agenda_default = moment(this.model.dt_agenda, 'DD/MM/YYYY').format('YYYY-MM-DD')
                    this.dataSend = { cd_agenda: null, cd_data: null, cd_horario: null }

                    this.getAgendas()
                }
            },
            onInputCriarHorario() {
                if (this.options.horarios.findIndex(h => h.value === 0) === -1) {
                    this.options.horarios.push(this.novoHorario);
                }
                this.dataSend.cd_horario = 0;
            },
            getAgendas () {
                this.options.agendas = []

                if( this.model.ie_tipo_agenda === 'C' ) {
                    this.waitingAgendas = true
                    Agenda.getEspecialistasAgendados({ especialidade: this.model.cd_especialidade }).then(response => {
                        if (response.status == 200)
                            response.data.forEach(agenda => {
                                if (agenda.id == this.model.cd_agenda) this.dataSend.cd_agenda = agenda.id
                                this.options.agendas.push({ value: agenda.id, text: `${agenda.nm_especialista}  (${agenda.nm_procedimento})` })
                            })
                        this.waitingAgendas = false
                        this.getDatas()
                    })
                } else {
                    this.dataSend.cd_agenda = this.model.cd_agenda
                    this.getDatas()
                }
            },
            getDatas () {
                this.modelDateDisabled = true
                this.dataSend.cd_data = null
                this.options.datas = []

                this.waitingDatas = true
                Agenda.getDatasDisponiveis(this.dataSend.cd_agenda, { id: this.model.id }).then(response => {
                    if (response.status === 200) {
                        this.options.datas = response.data.map(data => {
                            if (data.dt_agenda === this.model.dt_agenda_default) this.modelDateDisabled = false
                            return { value: data.dt_agenda, text: moment(data.dt_agenda).format('DD/MM/YYYY') }
                        })
                    }

                    if (this.dataSend.cd_agenda === this.model.cd_agenda) {
                        this.dataSend.cd_data = this.model.dt_agenda_default
                        if (this.modelDateDisabled) {
                            this.options.datas.unshift({ value: this.model.dt_agenda_default, text: this.model.dt_agenda })
                        }
                    }

                    this.waitingDatas = false
                    this.getHorarios()
                })
            },
            getHorarios () {
                this.dataSend.cd_horario = null
                this.options.horarios = []

                if (this.dataSend.cd_data == null) return

                if (this.dataSend.cd_agenda === this.model.cd_agenda) {
                    if (this.dataSend.cd_data === this.model.dt_agenda_default) {
                        this.dataSend.cd_horario = this.model.id

                        if (this.modelDateDisabled) {
                            this.options.horarios = [{ value: this.model.id, text: this.model.hr_agenda_f }]
                            return
                        }
                    }
                }

                this.waitingHorarios = true
                Agenda.getHorariosDisponiveis(this.dataSend.cd_agenda, { dt_agenda: this.dataSend.cd_data }).then(response => {
                    if (response.status === 200) {
                        this.options.horarios = response.data.map(h => {
                            if (h.id === this.model.id) return { value: h.id, text: h.hr_agenda, disabled: false }
                            return { value: h.id, text: h.hr_agenda, disabled: h.ie_status !== 'L' }
                        })
                    }
                    this.waitingHorarios = false
                })
            },
            handleSubmit (force) {
                if (this.dataSend.cd_horario === null) {
                    this.$toast.error('Informe o novo horário do agendamento', { duration: 3000 })
                } else if (this.dataSend.cd_horario === this.model.id) {
                    this.$toast.info('Agendamento sem modificação', { duration: 3000 })
                } else {
                    let dataSend = { cd_horario_novo: this.dataSend.cd_horario }
                    if (dataSend.cd_horario_novo === 0) {
                        dataSend.cd_agenda = this.dataSend.cd_agenda
                        dataSend.hr_agenda = this.novoHorario.text
                        dataSend.dt_agenda = this.dataSend.cd_data
                    }
                    this.waiting = true
                    Agendamento.transferirHorario(this.model.id, dataSend, force).then(response => {
                        this.waiting = false
                        if (([200, 201]).includes(response.status)) {
                            this.$toast.success('Agendamento atualizado!', { duration: 3000 })
                            this.$emit('saved')
                            this.show = false
                        } else if (response.status == 400) {
                            if (response.data.reenvio_adm) {
                                this.msgErro = response.data.detail
                                this.showForce = true
                            } else if (response.data.detail) {
                                if (typeof response.data.detail == 'string')
                                    this.$toast.error(response.data.detail, { duration: 3000 })
                                else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            }
                        }
                    })
                }
            }
        }
    }
</script>

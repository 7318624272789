<template>
    <Dialog
        class="dialog-retorno"
        header="Agendar retorno"
        :modal="true"
        :visible.sync="show"
        @keydown.esc="$emit('close')"
    >
        <DialogCriarHorario
            v-model="novoHorario.text"
            :visible.sync="showCriarHorario"
            @close="showCriarHorario = false"
            @input="onInputCriarHorario"
        />
        <form @submit.prevent="handleSubmit()">
            <div class="ta-center" v-if="waiting">
                <ProgressSpinner class="waiting-modal" strokeWidth="2" />
            </div>
            <div class="p-grid p-fluid p-align-end" v-else>
                <div class="p-col-12">
                    <label class="form-label">Procedimento:</label>
                    <InputText type="text" v-model="horario.nm_procedimento_f" disabled />
                </div>
                <div class="p-col-12">
                    <label class="form-label">Paciente:</label>
                    <InputText type="text" v-model="horario.nm_paciente" disabled />
                </div>
                <div class="p-col-12 p-md-5">
                    <label class="form-label">Especialidade:</label>
                    <InputText type="text" v-model="horario.ds_especialidade" disabled />
                </div>
                <div class="p-col-12 p-md-7">
                    <label class="form-label">Especialista:</label>
                    <InputText type="text" v-model="horario.ds_especialista" disabled />
                </div>
                <div class="p-col-12 p-md-4">
                    <label class="form-label">Data:</label>
                    <ProgressBar v-if='waitingDatas' mode="indeterminate" />
                    <span v-else-if="!options.datas.length">Nenhuma data disponível.</span>
                    <Dropdown
                        v-model="dtAgenda"
                        :options="options.datas"
                        optionLabel="text"
                        optionValue="value"
                        dataKey="value"
                        placeholder="Selecione"
                        @change="getHorarios()"
                        v-else
                    />
                </div>
                <div class="p-col-12 p-md-4">
                    <label class="form-label">Horário:</label>
                    <ProgressBar v-if='waitingHorarios' mode="indeterminate" />
                    <span v-else-if="!dtAgenda">Selecione a data.</span>
                    <span v-else-if="!options.horarios.length">Nenhum horário disponível.</span>
                    <CustomDropdown
                        v-model="idHorarioRetorno"
                        :options="options.horarios"
                        optionDisabled="disabled"
                        optionLabel="text"
                        optionValue="value"
                        dataKey="value"
                        placeholder="Selecione"
                        footer-text="+ CRIAR HORÁRIO"
                        @footer-click="showCriarHorario = true"
                        v-else
                    />
                </div>
                <div class="p-col-12 p-md-4 ta-center">
                    <ProgressSpinner class="waiting-submit" strokeWidth="6" v-if="waitingSubmit" />
                    <Button icon="jam jam-save" label="Agendar" :disabled="idHorarioRetorno === null" v-else />
                </div>
            </div>
        </form>
    </Dialog>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import ProgressBar from "primevue/progressbar";
import CustomDropdown from "@/components/CustomComponents/CustomDropdown";
import { Agenda } from "@/middleware";
import moment from "moment";
import DialogCriarHorario from "@/components/Agendamento/DialogCriarHorario";

export default {
    components: {DialogCriarHorario, Button, CustomDropdown, Dialog, Dropdown, InputText, ProgressSpinner, ProgressBar },
    props: ['visible', 'force', 'horario'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    mounted() {
        this.horario.nm_procedimento_f = `${ this.horario.cd_cbhpm } - ${ this.horario.nm_procedimento }`;
        this.getDatas();
    },
    data () {
        return {
            idHorarioRetorno: null,
            dtAgenda: null,
            dataSend: {
                cd_agenda: null,
                cd_data: 0,
                cd_horario: 0
            },
            options: {
                datas: [],
                horarios: [],
            },
            waiting: false,
            waitingSubmit: false,
            waitingDatas: false,
            waitingHorarios: false,
            showCriarHorario: false,
            novoHorario: {
                value: 0,
                text: null,
                disabled: false
            }
        }
    },
    methods: {
        onInputCriarHorario() {
            if (this.options.horarios.findIndex(h => h.value === 0) === -1) {
                this.options.horarios.push(this.novoHorario);
            }
            this.idHorarioRetorno = 0;
        },
        getDatas () {
            this.dtAgenda = null;
            this.options.datas = [];

            this.waitingDatas = true;
            Agenda.getDatasRetorno(this.horario.id, { force: this.force }).then(response => {
                if (response.status === 200) {
                    this.options.datas = response.data.map(dt => {
                        return { value: dt, text: moment(dt).format('DD/MM/YYYY') };
                    });
                } else if (response.status === 400 && response.data.detail) {
                    this.$toast.error(response.data.detail, { duration: 3000 });
                    this.show = false;
                }
                this.waitingDatas = false;
            })
        },
        getHorarios () {
            this.idHorarioRetorno = null;
            this.options.horarios = [];

            if (! this.dtAgenda) return;

            this.waitingHorarios = true;
            Agenda.getHorariosRetorno(this.horario.id, { dt_agenda: this.dtAgenda }).then(response => {
                if (response.status === 200) {
                    this.options.horarios = response.data.map(horario => {
                        horario.hr_agenda_f = moment(horario.hr_agenda, 'HH:mm:ss').format('HH:mm');
                        return { value: horario.id, text: horario.hr_agenda_f, disabled: horario.ie_status !== 'L' };
                    });
                }
                this.waitingHorarios = false;
            })
        },
        handleSubmit () {
            if (this.idHorarioRetorno === null) {
                this.$toast.error('Selecione o horário.', { duration: 3000 });
                return;
            }

            let dataSend = { cd_horario_retorno: this.idHorarioRetorno };
            if (this.idHorarioRetorno === 0) {
                dataSend.dt_agenda_encaixe = this.dtAgenda;
                dataSend.hr_agenda_encaixe = this.novoHorario.text;
            }

            this.waitingSubmit = true;
            Agenda.agendarRetorno(this.horario.id, dataSend, this.force).then(response => {
                this.waitingSubmit = false;
                if ([200, 201].includes(response.status)) {
                    this.$toast.success('Retorno agendado com sucesso.', { duration: 3000 });
                    this.$emit('saved');
                    this.show = false;
                } else if ([400, 403].includes(response.status)) {
                    if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 });
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss">
.agendamento-listar {
    .dialog-retorno {
        @media all and (min-width: 577px) {
            max-width: 640px;
            width: 100%;
        }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .mtd-badge {
            margin-top: 6px;
            font-size: 14px;
            padding: 6px 12px;
        }
        .p-dialog-content {
            overflow: visible !important;
        }
        .waiting-modal {
            width: 80px;
            margin: 40px auto;
        }
        .waiting-submit {
            width: 25px;
            height: auto;
        }
    }
}
</style>

<template>
    <Dialog class="dialog-criar-horario" header="Criar horário" :visible.sync='show' :modal='true'>
        <div :class="['ta-center my-3', {'form-group--error': submitted && $v.horario.$error}]">
            <label class="form-label">Horário de Atendimento:</label>
            <InputMask class="ta-center" v-model="$v.horario.$model" mask="99:99" />
            <div class="feedback--errors" v-if='submitted && $v.horario.$error'>
                <div class="form-message--error" v-if="!$v.horario.validateTime">Horário inválido.</div>
            </div>
        </div>
        <div class="ta-center my-4">
            <Button label="Criar" @click="criarHorario()" />
        </div>
    </Dialog>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputMask from "primevue/inputmask";
import moment from "moment";

export default {
    components: { Button, Dialog, InputMask },
    props: ['value', 'visible'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    data() {
        return {
            horario: null,
            submitted: false
        }
    },
    validations () {
        return {
            horario: { validateTime: this.validateTime }
        }
    },
    methods: {
        criarHorario() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.$emit('input', this.horario);
            this.$emit('change', this.horario);
            this.horario = null;
            this.submitted = false;
            this.show = false;
        },
        validateTime() {
            return moment(this.horario, 'HH:mm', true).isValid();
        }
    }
}
</script>

<style lang="scss">
.dialog-criar-horario {
    width: 350px;
}
</style>

<template lang="pug">
    Dialog(
        class="dialog-detalhes-agenda"
        header="Agenda"
        :modal="true"
        :visible.sync="show"
        @keydown.esc="$emit('close')"
        @show="getAgenda()"
    )
        DialogListar(:display.sync="dialogAgendamentos" :filters="filtersAgendamentos" @close="dialogAgendamentos=false")

        ProgressBar(v-if='waiting' mode="indeterminate")
        Panel.my-2(v-else header='Detalhes da Agenda')
            .p-grid.p-fluid.p-justify-center
                .p-col-12.p-md-6
                    .p-grid.p-fluid.box-info

                        .box-featured.p-col-12.my-4
                            .p-grid.p-fluid
                                .p-col-12.aux-2
                                    p <b>Estabelecimento: </b> {{ model.nm_estabelecimento }}
                                .p-col-12.aux-2
                                    p <b>Procedimento(s): </b>
                                        span {{ model.nm_procedimento }} <br>
                                        div(v-if='model.cd_procedimentos !== undefined && model.cd_procedimentos.length > 0' v-for='procedimento in model.cd_procedimentos')
                                            span(v-if="procedimento.ie_status") &bull; {{ procedimento.ds_procedimento }} <br>
                                            span(v-else) &bull; <b>{{ procedimento.ds_procedimento }}</b> (desativado) <br>
                                .p-col-12.aux-2(v-if="model.ie_tipo_agenda === 'C'")
                                    p <b>Especialista: </b> {{ model.nm_especialista }}
                                .p-col-12.aux-2(v-if="model.ie_tipo_agenda === 'C'")
                                    p <b>Especialidade(s): </b>
                                        span.mtd-badge.co1(v-for='especialidade in model.cd_especialidades') {{ especialidade.nm_especialidade }}

                        .p-col-12.p-md-6.aux-2
                            p <b>Sexo: </b> {{ model.ie_sexo_f }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Hora marcada: </b> {{ model.ie_hora_marcada ? 'Sim' : 'Não' }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Presencial: </b> {{ model.ie_agenda_presencial ? 'Sim' : 'Não' }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Por vídeo: </b> {{ model.ie_telemedicina ? 'Sim' : 'Não' }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Grátis: </b> {{ model.ie_agenda_gratis ? 'Sim' : 'Não' }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Quantidade de vagas: </b> {{ model.qtd_vaga }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Idade: </b> De {{ model.nr_idade_minima }} a {{ model.nr_idade_maxima }} anos
                        .p-col-12.p-md-6.aux-2
                            p <b>Id Integração: </b> {{ model.id_externo }}
                        .p-col-12.aux-2.mt-4(v-if='model.ds_observacao')
                            p <b>Observações: </b>
                            Textarea(v-model='model.ds_observacao' :autoResize="true" rows="5" readonly)
                        .p-col-12.aux-2.mt-4(v-if='model.ds_termo_pronto_socorro')
                            p <b>Termo: </b>
                            Textarea(v-model='model.ds_termo_pronto_socorro' :autoResize="true" rows="5" readonly)
                        .p-col-12.p-md-6.aux-2
                            p <b>Usuario original: </b> {{ model.nm_usuario_cri }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Data da criação: </b> {{ model.dt_criado }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Usuario edição: </b> {{ model.nm_usuario_edi }}
                        .p-col-12.p-md-6.aux-2
                            p <b>Data da atualização: </b> {{ model.dt_atualizado }}
                        .p-col-12.aux-2(v-if='model.ds_orientacao')
                            p <b>Informações de preparo: </b>
                            Textarea(v-model='model.ds_orientacao' :autoResize="true" rows="5" readonly)
                .p-col-12.p-md-6.mt-4
                    CalendarioComponent(ref='calendarioComponent' @dayclick="displayAtendimentos")
                    .mt-2
                        label.form-label Mês
                        Calendar(v-if='$refs.calendarioComponent'
                            v-model='$refs.calendarioComponent.filters.data'
                            dateFormat="mm/yy"
                            :yearRange="`2020:${proximoAno}`"
                            view='month'
                            :locale="ptbr"
                            :manualInput='false'
                            :yearNavigator='true'
                            @input='getCalendario()')


        ProgressBar(v-if='waitingAtendimentos' mode="indeterminate")
        Panel.my-2(v-else header='Dias de atendimento')
            .p-grid
                Panel.aux-3.p-col-12.p-md-3(v-for='dia in Object.keys(diasSemanaDictionary)' :key='dia' :header='diasSemanaDictionary[dia].short')
                    div(v-if='!listDias[dia].length')
                        p.aux-1 Não possui atendimentos nesse dia.
                    DataView.my-2(v-else :value="listDias[dia]" layout="grid")
                        template(#grid="props")
                            .p-col-12(style='padding: .5em' :class="{ disabled: !props.data.ie_ativo }")
                                Panel.panel-list.ta-center(:header='`DAS ${ props.data.hr_inicio_atendimento_fs } ÀS ${ props.data.hr_fim_atendimento_fs }`'
                                    style='position: relative')
                                    .status-indicator(:class='{ off: !props.data.ie_ativo }' v-tooltip.top="props.data.ie_ativo ? 'Habilitado' : 'Desabilitado'")
                                        span(:class="{ 'jam-check': props.data.ie_ativo, 'jam-close': !props.data.ie_ativo  }").jam
                                    .ta-center
                                        p <b>De {{ props.data.dt_vigencia_inicial_f }} a {{ props.data.dt_vigencia_final_f }}</b>
                                        p <b>Das {{ props.data.hr_inicio_atendimento_f }} às {{ props.data.hr_fim_atendimento_f }}</b>
                                        p <b>Atende em feriado:</b> {{ props.data.ie_atende_feriado ? 'Sim' : 'Não' }}
                                        p <b>{{ props.data.qtd_vaga }} vagas</b>

</template>

<script>
import Calendar from 'primevue/calendar';
import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';

import CalendarioComponent from './../Agendamento/Calendario-component';
import DialogListar from "@/components/Agendamento/DialogListar";
import moment from 'moment';
import { Agenda } from './../../middleware';
import { pCalendarLocale_ptbr } from '@/utils';

export default {
    components: {
        Calendar, CalendarioComponent, DataView, Dialog, DialogListar, Panel, ProgressBar, Textarea
    },
    directives: { tooltip: Tooltip },
    props: ['visible', 'cd_especialista'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    data () {
        return {
            model:{},
            cd_agenda_id: null,
            waiting: true,
            waitingAtendimentos: false,
            ptbr: pCalendarLocale_ptbr,
            listDias: {
                SEGUNDA: [], TERCA: [], QUARTA: [], QUINTA: [], SEXTA: [], SABADO: [], DOMINGO: []
            },
            diasSemanaDictionary: {
                'SEGUNDA': { name: 'Segunda-Feira', short: 'SEGUNDA' },
                'TERCA': { name: 'Terça-Feira', short: 'TERÇA' },
                'QUARTA': { name: 'Quarta-Feira', short: 'QUARTA' },
                'QUINTA': { name: 'Quinta-Feira', short: 'QUINTA' },
                'SEXTA': { name: 'Sexta-Feira', short: 'SEXTA' },
                'SABADO': { name: 'Sábado', short: 'SÁBADO' },
                'DOMINGO': { name: 'Domingo', short: 'DOMINGO' }
            },
            proximoAno: parseInt(moment().format("YYYY"))+1,
            dialogAgendamentos: false,
            filtersAgendamentos: {
                dt_inicio: '',
                dt_fim: '',
                order: 'dt_agenda',
                ie_status: ['A', 'EX', 'L', 'BO'],
                ie_tipo_agenda: '',
                especialista: null
            }
        }
    },
    methods: {
        getAgenda() {
            if (!this.cd_especialista) this.$emit('close');

            this.waiting = true;
            this.waitingAtendimentos = true;

            Agenda.findByEspecialista(this.cd_especialista).then(response => {
                if (response.status === 200) {
                    this.model = response.data;
                    this.cd_agenda_id = response.data.id;

                    let ie_sexo_options = {
                        'M': 'Masculino',
                        'F': 'Feminino',
                        'N': 'Não aplicável'
                    };

                    this.model.ie_sexo_f = ie_sexo_options[this.model.ie_sexo];

                    let procedimento = this.model.cd_procedimento_estabelecimento;
                    if (procedimento != null) {
                        this.model.nm_procedimento = procedimento.ds_observacao || procedimento.cd_procedimento.nm_procedimento
                    } else {
                        this.model.nm_procedimento = ''
                    }

                    this.getAtendimentos()

                    setTimeout(() => {
                        this.$refs.calendarioComponent.filters.data = moment()._d
                        this.getCalendario()
                        this.formatarData()

                    })

                    this.waiting = false

                } else this.$router.go(-1)
            })
        },
        formatarData(){
            this.model.dt_criado = moment(this.model.dt_criado).format('DD/MM/YYYY')
            if (this.model.dt_atualizado){
                this.model.dt_atualizado = moment(this.model.dt_atualizado).format('DD/MM/YYYY')
            }
        },
        getCalendario () {
            this.$refs.calendarioComponent.filters.cd_agenda = this.model.id
            this.$refs.calendarioComponent.getCalendario()
        },
        getAtendimentos () {
            this.waitingAtendimentos = true
            Object.keys(this.listDias).forEach(dia => this.listDias[dia] = [])
            Agenda.getAtendimentos(this.model.id).then(response => {
                if (response.status === 200) {
                    response.data.forEach(i => {
                        i.nm_dia_semana_f = this.diasSemanaDictionary[i.nm_dia_semana].name
                        i.nm_dia_semana_fs = this.diasSemanaDictionary[i.nm_dia_semana].short
                        i.dt_vigencia_inicial_f = moment(i.dt_vigencia_inicial).format('DD/MM/YYYY')
                        i.dt_vigencia_final_f = moment(i.dt_vigencia_final).format('DD/MM/YYYY')
                        i.hr_inicio_atendimento_f = moment(i.hr_inicio_atendimento, 'HH:mm:ss').format('HH:mm')
                        i.hr_fim_atendimento_f = moment(i.hr_fim_atendimento, 'HH:mm:ss').format('HH:mm')

                        let _d = (str => str.substr(3, 2) === '00' ? `${ str.substr(0, 2) }h` : `${ str.substr(0, 2) }h${ str.substr(3, 2) }`)
                        i.hr_inicio_atendimento_fs = _d(i.hr_inicio_atendimento_f)
                        i.hr_fim_atendimento_fs = _d(i.hr_fim_atendimento_f)

                        this.listDias[i.nm_dia_semana].push(i)
                    })
                }

                this.waitingAtendimentos = false
            })
        },
        displayAtendimentos(ev) {
            const key = ev.popovers[0]?.key
            if (['HV', 'TO', 'BO', 'TL'].includes(key)) {
                this.filtersAgendamentos.cd_agenda = this.model.id
                this.filtersAgendamentos.dt_inicio = ev.id
                this.filtersAgendamentos.dt_fim = ev.id
                this.filtersAgendamentos.ie_tipo_agenda = this.model.ie_tipo_agenda
                this.dialogAgendamentos = true
            }
        }
    }
}
</script>

<style lang="scss">
.dialog-detalhes-agenda {
    width: 94%;
    max-width: 1500px;

    .disabled {
        p { color: #aaa; }
        .p-panel-titlebar {
            span { color: #aaa; }
        }
        .p-panel-content {
            background-color: #f4f4f4 !important;
        }
    }
    .aux-1 { font-size: 13px; font-weight: 700; text-align: center; margin: 40px 0; }
    .aux-2 { padding: 2px 10px; }
    .aux-3 { padding: 8px; .p-dataview-content { border: none; padding: 0; } }
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 9px;
        right: 9px;
        color: #fff;
        background-color: #94c860;
        border: 1px solid #84b553;
        .jam {
            font-size: 18px;
            width: 100%;
            text-align: center;
        }
        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
        }
    }
    .p-inputtextarea {
        overflow-y: auto !important;
        cursor: default;
    }
    .box-info {
        padding: 10px 20px;
    }
    .box-featured {
        background-color: #f8f9fa;
        border: 1px solid #e9ecef;
        border-radius: 8px;
        padding: 18px 10px 10px;
        p {
            font-size: 16px;
        }
    }
}
</style>

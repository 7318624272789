<template lang="pug">
div    
    Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' )
        .p-grid.p-fluid.p-align-end
            .p-col-12(v-for="data in filterData" :class="data.classes")
                label.form-label(v-text="data.label")
                .p-inputgroup
                    InputText(
                        v-if='data.type == "inputText"'
                        :placeholder='data.placeholder'
                        :autoClear='false'
                        v-model='filters[data.model]'
                        @input="data.searchOnType ? emitFilters(filters) : null"
                        @keyup.enter.native='emitFilters(filters)'
                    )
                    InputNumber(
                        v-if='data.type == "inputNumber"'
                        :placeholder='data.placeholder'
                        :autoClear='false'
                        v-model='filters[data.model]'
                        @input="data.searchOnType ? emitFilters(filters) : null"
                        @keyup.enter.native='emitFilters(filters)'
                    )
                    MultiSelect(v-if='data.type == "multiselect"' :options='options[data.model]' optionValue='value' optionLabel='label' v-model='filters[data.model]' :placeholder='data.placeholder' :filter='data.filter' @change='emitFilters(filters)')
                    Dropdown(v-if='data.type == "dropdown"' :options='options[data.model]' optionValue='value' optionLabel='label' v-model='filters[data.model]' :placeholder='data.placeholder' :filter='data.filter' @change='emitFilters(filters)')
                    Button(v-if='data.button' :icon='data.button.icon' @click='emitFilters(filters)')
</template>
<script>
    import Panel from 'primevue/panel';
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';
    import Button from 'primevue/button';
    import MultiSelect from 'primevue/multiselect';
    import Dropdown from 'primevue/dropdown';
    import _ from 'lodash';

    export default {
        // created() { this.createFilter() },
        mounted() { this.createFilter() },
        components: { Panel, InputText, InputNumber, Button, MultiSelect, Dropdown, },
        props: ["filterData"],
        data(){
            return {
                windowInnerWidth: window.innerWidth,
                filters: {},
                options: {}
            }
        },
        methods: {
            createFilter() {
                const filters = _.transform(this.filterData, (res, v) => {
                    res[v.model] = v.default ?? null
                    if(v.options) this.options[v.model] = v.options
                }, {})
                this.filters = filters
                this.emitFilters(filters)
            },
            emitFilters(val) {
                this.$emit('emitFilters', val)
            },
        }
    }
</script>
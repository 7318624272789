<template lang="pug">
.main-wrapper.lista
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Empresas</b>
    CustomFilters(:filterData='filterData' @emitFilters='emitFilters')
    .ta-right.my-4
        Button.ml-2(label='Criar Atendimento' icon="jam jam-calendar" @click="redirectToAtendimento()")
    CustomDataTable(:waiting="createList" :header='"Atendimentos MedClub Empresa"' :tValue='tableValue' :tData='tableData' :table-props="tableProps" :paginator="paginator" @page='onPage')
</template>

<script>
    import Listar from '../Agendamento/Listar.vue'
    import CustomDataTable from '../CustomComponents/CustomDataTable.vue';
    import Button from 'primevue/button';
    import { MedEmpresa } from '@/middleware'
    import moment from 'moment';
    import { formatPrice } from '@/utils';
    import CustomFilters from '../CustomComponents/CustomFilters.vue';
    // import wsConfigs from './../../middleware/configs'
    import _ from 'lodash'

    export default {
        created() { this.montaFiltros() },
        components: { Listar, CustomDataTable, CustomFilters, Button },
        data () {
            return {
                form: false,
                createList: false,
                filters: {
                    ie_status: ''
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: 5,
                    count: 0
                },
                atendimentoSelected: '',
                order: {
                    field: 'id',
                    sortOrder: 1
                },
                filterData: [],
                options: {
                    ie_status: [
                        { value: null, label: '- TODOS -' },
                        { value: 'A', label: 'Aberto', color: "#c0e8ff" },
                        { value: 'F', label: 'Finalizado', color: "#b9ffaf" },
                        { value: 'V', label: 'Vencido', color: "#ff7575c9" },
                    ],
                },
                getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 500, { leading: true }),
                tableValue: [],
                tableProps: {},
                tableData: [
                    {header: 'Nº Atendimento', field: 'id', hStyle: {width:'10%'},
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {header: 'Cliente', field: 'nm_pessoa_fisica', hStyle: {width: '28%'}},
                    {header: 'Empresa Associada', field: 'nm_empresa', hStyle: {width:'15%'}},
                    {header: 'Data', field: 'dt_inicio', hStyle: {width:'10%'}, 
                        body: [
                            {
                                bType: 'custom', 
                                custom: this.date
                            }
                        ],
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {header: 'Qtd.', field: 'nr_quant_itens', hStyle: {width: '5%'}, 
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {header: 'Valor Total', field: 'valor_total', hStyle: {width:'10%'}, 
                        bStyle: {
                            textAlign: 'center'
                        },
                        body: [
                            {
                                bType: 'custom',
                                custom: this.precifica
                            }
                        ]
                    },
                    {header: 'Situação', field: 'ie_status', hStyle: {width:'10%'},
                        body: [
                            {
                                bType: 'custom',
                                custom: this.listaSituacao,
                            }
                        ],
                        bStyle: {
                            textAlign: "center",
                            padding: '0 !important',
                            height: "1px"
                        }
                    },
                    {header: 'Ações', field: '', hStyle: {width: "7%"},
                        body: [
                            {
                                bType: 'button',
                                icon: 'jam jam-chevron-right',
                                classes: 'p-button-rounded',
                                tooltip: 'Consultar',
                                action: this.redirectToAtendimento
                            }
                        ],
                        bStyle: {
                            textAlign: 'center',
                            padding: 0
                        }
                    },
                ]
            }
        },
        methods: {
            getList (params) {
                this.createList = true
                MedEmpresa.getAtendimentos({ ...params, ie_simplificado: true })
                .then((res) => {
                    if([200,201,204].includes(res.status)){
                        this.paginator.count = res.data.count
                        this.tableValue = res.data.results
                        this.createList = false
                    } else this.$toast.error(res.data.detail)
                })
            },
            applyFilters(page) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                    if (key == 'nr_name') params[key] = this.filters[key].replace(/(\.|-|_)/g, '')
                    else if (key.substr(0, 3) == 'dt_') {
                        if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
                            params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
                    } else if (this.filters[key]) params[key] = this.filters[key]
                })
                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params)) {
                    if (!this.createList) {
                        this.createList = true
                        setTimeout(() => this.createList = false, 200)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.createList = true
                    this.getListDebounce(params, this.getList)
                }
            },
            precifica (item) {
                return formatPrice(item.nr_valor_total)
            },
            date (item) {
                return `${moment(item.dt_inicio).format('DD/MM/YYYY')} <b>(${item.nm_usuario_cri})</b>`
            },
            listaSituacao (item) {
                const sit = _.find(this.options.ie_status, ["value", item.ie_status])
                const style = `
                    background-color: ${sit.color};
                    height: 100%;
                    display: grid;
                    align-content: center;
                `
                return `<b style='${style}'> ${sit.label} </b>`
            },
            montaFiltros() {
                this.filterData = [
                    { 
                        label: 'Atendimento:',
                        type: 'inputNumber', 
                        placeholder: 'Ex: 123456', 
                        model: 'id',
                        classes: 'p-md-2',
                        button: {icon: 'jam jam-search'}
                    },
                    { 
                        label: 'Cliente:',
                        type: 'inputText', 
                        placeholder: 'Nome do Cliente', 
                        model: 'nm_pessoa_fisica',
                        classes: 'p-md-4',
                        button: { icon: 'jam jam-search' }
                    },
                    {
                        label: 'Situação:',
                        type: 'dropdown', 
                        placeholder: 'TODOS',
                        model: 'ie_status',
                        options: this.options.ie_status,
                        filter: false,
                        classes: 'p-md-2 ',
                        default: 'A'
                    }
                ]
            },
            emitFilters(filtros){
                _.assign(this.filters, filtros)
                this.applyFilters();
            },
            redirectToAtendimento(data) {
                this.$router.push({ path: `/med-empresa/atendimento/${data?.id ?? 0}/`})
                sessionStorage.setItem('tipo_atendimento', 'E')
            },
            onPage(ev) {
				this.applyFilters(ev.page+1)
            }
        }
    }
</script>
<style lang="scss">
.lista {
    .dataview {
        @media all and (min-width: 641px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 640px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex  {  background-color: #e4f8e1; }
            &.a  { background-color: #faf3dd; }
        }
    }
}
</style>
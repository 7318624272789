<template lang="pug">
.main-wrapper.agendamento-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Agendamentos</b>

    Visualizar
    Editar(
        :selectedRow="selectedRow"
        :visible="dialogEditar"
        @close="dialogEditar = false"
        @saved="applyFilters(paginator.page, true)" )
    DialogRetorno(
        :horario="selectedRow"
        :visible="dialogRetorno"
        :force="forceRetorno"
        v-if="dialogRetorno"
        @close="dialogRetorno = false; forceRetorno = false"
        @saved="onSavedDialogRetorno()")

    ConfirmDialog(
        :display="checkin.showDialog"
        @confirmed="onConfirmCheck(checkin.horario, 'checkin', checkin.isDesfazer)"
        @close="checkin.showDialog = false")
        template(#text-content="props")
            span Deseja mesmo <b> {{ checkin.isDesfazer ? 'desfazer' : 'confirmar' }}
                |  checkin</b> do(a) paciente <b>{{ checkin.horario.nm_paciente }}</b>?

    ConfirmDialog(
        :display="checkout.showDialog"
        @confirmed="onConfirmCheck(checkout.horario, 'checkout', checkout.isDesfazer)"
        @close="checkout.showDialog = false")
        template(#text-content="props")
            span Deseja mesmo <b> {{ checkout.isDesfazer ? 'DESFAZER' : 'CONFIRMAR' }}
                |  checkout</b> do(a) paciente <b>{{ checkout.horario.nm_paciente }}</b> ?

    DialogDetalhesAgenda(
        :visible="dialogAgenda"
        :cd_especialista="this.filters.especialista"
        @close="dialogAgenda = false")

    TabView(@tab-change='onTabChange')
        TabPanel(header='Consultas' v-bind:ieTipoAgenda="'C'" :active="filters.ie_tipo_agenda == 'C'" :disabled='waiting')
        TabPanel(header='Exames'  v-bind:ieTipoAgenda="'E'" :active="filters.ie_tipo_agenda == 'E'" :disabled='waiting')
        TabPanel(header='Procedimentos'  v-bind:ieTipoAgenda="'P'" :active="filters.ie_tipo_agenda == 'P'" :disabled='waiting')

    Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
        .p-grid.p-fluid.p-align-end

            .p-col-12.p-md-2
                label.form-label Código da guia:
                .p-inputgroup
                    InputText( @keyup.enter.native='applyFilters()' v-model='filters.cd_transacao_financeira'
                        @keyup='checkEmptyField("cd_guia")' )
                    Button(icon='jam jam-search' @click='applyFilters()')

            .p-col-12.p-md-2
                label.form-label Origem do agendamento:
                Dropdown( v-model='filters.ie_unidade' :options='options.ie_unidade'
                    dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

            .p-col-12.p-md-4(v-if="filters.ie_tipo_agenda == 'C'")
                label.form-label Especialista:
                Dropdown( v-model='filters.especialista' :options='options.especialistas' dataKey='value' :filter='options.especialistas.length > 0'
                    optionLabel='text' optionValue='value' placeholder='TODOS' @change='applyFilters()' )

            .p-col-12.p-md-4(v-if="filters.ie_tipo_agenda == 'C'")
                label.form-label Especialidade:
                Dropdown( v-model='filters.especialidade' :options='options.especialidades' :filter='options.especialidades.length > 0'
                    dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

            .p-col-12.p-md-4(v-if="filters.ie_tipo_agenda == 'E' || filters.ie_tipo_agenda == 'P'")
                label.form-label Procedimento:
                Dropdown( v-model='filters.cd_procedimento_estabelecimento' :options='options.procedimentos' :filter='options.procedimentos.length > 0'
                    dataKey='value' optionLabel='text' optionValue='value' placeholder='TODOS' @change='applyFilters()' )

            .p-col-12.p-md-4(v-if="['E', 'P'].includes(filters.ie_tipo_agenda)")
                label.form-label Grupo de Procedimentos:
                ProgressBar(v-if='!options.grupos_procedimentos.length' mode='indeterminate')
                Dropdown(v-else v-model='filters.grupo_procedimento' :options='options.grupos_procedimentos' :filter='!!options.grupos_procedimentos.length'
                    dataKey='value' optionLabel='text' optionValue='value' placeholder='TODOS' @change='applyFilters()' )

            .p-col-6.p-md-2
                label.form-label Data inicial:
                .p-inputgroup
                    Calendar( v-model='filters.dt_inicio' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_fim'
                        :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                    Button.p-button-danger(icon='jam jam-rubber' @click='filters.dt_inicio = null; applyFilters()')

            .p-col-6.p-md-2
                label.form-label Data final:
                .p-inputgroup
                    Calendar( v-model='filters.dt_fim' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_inicio'
                        :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                    Button.p-button-danger(icon='jam jam-rubber' @click='filters.dt_fim = null; applyFilters()')

            //- .p-col-12.p-md-2
            //-     label.form-label Data:
            //-     .p-inputgroup
            //-         Button(icon='jam jam-chevron-left' @click='prevDay()')
            //-         Calendar.filter-data( v-model='filters.dt_inicio' dateFormat="dd/mm/yy" :locale="ptbr"
            //-             :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
            //-         Button(icon='jam jam-chevron-right' @click='nextDay()')

            .p-col-12.p-md-4(:class="{ 'form-group--error': invalidNmPaciente, 'shake-animation': shakeError }")
                label.form-label Nome do paciente:
                .p-inputgroup
                    InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente'
                        @input='(val) => !val && applyFilters()' )
                    Button(icon='jam jam-search' @click='applyFilters()')
                .feedback--errors(v-if='invalidNmPaciente')
                    .form-message--error(v-if="invalidNmPaciente") Deve ter pelo menos 5 caracteres.

            .p-col-12.p-md-2
                label.form-label Situação:
                MultiSelect.input-ieStatus( v-model='filters.ie_status' :options='options.ie_status'
                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' @hide='applyFilters()' )

            .p-col-12.p-md-2
                label.form-label CheckIn:
                Dropdown( v-model='filters.com_checkin' :options='options.com_checkin'
                    dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

            .p-col-12.p-md-2
                Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

    .p-grid
        .p-col-4.my-4
            Button.p-button-rounded(
                v-if="filters.ie_tipo_agenda === 'C'"
                :class="['p-button-warning', { 'custom-disabled': !filters.especialista }]"
                v-tooltip.right="`Detalhes da agenda ${ !filters.especialista ? '(selecione o especialista)' : '' }`"
                icon="jam jam-info"
                @click="openDialogAgenda()"
                )
        .p-col-8
            .ta-right.my-4
                Button.ml-2.btn-calendario(label='Ver calendário' icon="jam jam-calendar" role="link" @click="openInNewTab('/agendamento/calendario')")
                Button.ml-2.btn-calendario(label='Ver preços' icon="jam jam-coin" role="link" @click="openInNewTab('/preco-procedimentos')")
                Button.ml-2.btn-criarAgendamento(label='Agendar' icon="jam jam-plus" role="link" @click="openInNewTab('/agendamento/salvar/0/')")

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

    div(v-else)
        DataView.my-2.dataview(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em")
                    Panel.panel-list.ta-center(:header="props.data.nm_paciente.split(' ')[0]")
                        .ta-left
                            p <b>Horário:</b> {{ props.data.hr_agenda_f }}
                            p <b>Nome do paciente:</b> {{ props.data.nm_paciente }}
                            p(v-if="filters.ie_tipo_agenda == 'C'") <b>Especialidade:</b> {{ props.data.ds_especialidade }}
                            p(v-if="filters.ie_tipo_agenda == 'C'") <b>Especialista:</b> {{ props.data.ds_especialista }}
                            p <b>Situação: </b>
                                span.mtd-badge(:class="{ co3: props.data.ie_status == 'A', co5: props.data.ie_status == 'EX' }") {{ props.data.ie_status_f }}
                            p(v-if='props.data.cdGuiaEletronica') <b>Código da guia:</b> {{ props.data.cdGuiaEletronica }}
                            p(v-if="filters.ie_tipo_agenda == 'E'") <b>Procedimento:</b> {{ props.data.nm_procedimento }}
                        .ta-right.mt-3
                            Button.p-button-info(label='Visualizar' @click='selectedRow=props.data; dialogVisualizar = true')
        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable
            template(#header)
                .p-sol-12(:style="{'display': 'flex'}")
                    .p-col-6.ta-left
                        b Lista de agendamentos
                    .p-col-6.ta-right
                        b Total de Itens: {{ totalItens }}
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:value="list" @sort='onSort($event)' removableSort :sortField='order.field' :sortOrder='order.sortOrder' sortMode='single')
                Column(headerStyle='width: 3%;' bodyStyle='text-align: center;')
                    template(#header)
                        span(v-tooltip.top="'Ordem de chamada'") #
                    template(#body='{data}')
                        span(v-if='data?.nr_sequencia') {{ data.nr_sequencia }}º
                // :sortable='true'
                Column(headerStyle='width: 9%;' bodyStyle='text-align: center;' header='Horário')
                    template(#body='props')
                        p
                            span {{props.data.dt_agenda_f}} <br/>
                            em {{props.data.hr_agenda_f}}
                Column(headerStyle='width: 15%;' header='Nome do paciente')
                    template(#body='props')
                        p
                            span {{props.data.nm_paciente}} {{ props.data.nr_idade_paciente?`(${props.data.nr_idade_paciente} anos)`:'' }}<br />
                            em(:style={'font-size':'10px'}) Agendado em: {{props.data.dt_agendamento_f}}
                Column(v-if="filters.ie_tipo_agenda == 'C'" headerStyle='width: 14%;' field='ds_especialidade' header='Especialidade')
                Column(v-if="filters.ie_tipo_agenda == 'C'" headerStyle='width: 14%;' field='ds_especialista' header='Especialista')
                Column(v-if="filters.ie_tipo_agenda == 'E'" headerStyle='width: 28%;' header='Procedimento(s)')
                    template(#body='props')
                        p(v-if='props.data.nm_procedimento != "" && props.data.nm_procedimento != "-"') {{props.data.nm_procedimento}}
                        p(v-else v-for='procedimento in props.data.cd_procedimentos')
                            span &bull; {{ procedimento.nm_procedimento }}
                Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header='Situação')
                    template(#body='props')
                        .cell(:style="{ 'background-color': props.data.status_color }" :title="props.data.nm_motivo_bloqueado")
                            span {{props.data.ie_status_f}} <br />
                            span(v-if="['EX','A', 'R', 'AG'].includes(props.data.ie_status)" style='font-size:10px') ({{props.data.ie_unidade_f}}) <br />
                            em(v-if="props.data.dt_chegada" :style={'font-size':'10px'})
                                b Checkin: {{ props.data.dt_chegada_f }} <br/>
                            em(v-if="props.data.dt_saida" :style={'font-size':'10px'})
                                b Checkout: {{ props.data.dt_saida_f }}
                Column(v-if="filters.ie_tipo_agenda == 'C'" headerStyle='width: 7%;' header='Tipo' bodyStyle='text-align:center')
                    template(#body='props')
                        span {{ props.data.ie_retorno ? 'Retorno' : 'Normal' }}
                //Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='cdGuiaEletronica' header='Cód. guia')
                Column(headerStyle='width: 15%;' field='nr_telefone_paciente_f' header='Telefone')
                    template(#body='props')
                        p.ta-center(style='margin:0')
                            span(style='display:inline-block;vertical-align:middle') {{ props.data.nr_telefone_paciente_f }}
                            a.whatsapp-link(v-if='props.data.nr_telefone_paciente' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_paciente_ws }`" target='_blank')
                                i(class='jam jam-whatsapp')
                Column(v-if="['C', 'E'].includes(filters.ie_tipo_agenda)" headerStyle='width: 10%;' header='Prioridade' bodyStyle='text-align:center' sortable field='ie_prioridade_nivel_f')
                    template(#body='props')
                        ProgressSpinner.waiting-prioridade(v-if='waitingPrioridadeIds.includes(props.data.id)' strokeWidth='6')
                        div(v-else-if="props.data.ie_status != 'L'" :ref="'span' + props.data.id")
                            Button.p-button-raised.p-button-rounded(
                                :ref="'btn' + props.data.id"
                                type='button'
                                :label='options.prioridades[props.data.ie_prioridade_nivel].label'
                                :class="options.prioridades[props.data.ie_prioridade_nivel].color"
                                v-tooltip.rigth="options.prioridades[props.data.ie_prioridade_nivel].tooltip"
                                @click='mostrarPrioridades(props.data)'
                            )
                            transition( name='fade' @enter="onOverlayEnter(props.data)" @leave="onOverlayLeave(props.data)" )
                                .menu-prioridade( v-if='showPrioridade == props.data.id' )
                                    .menu-prioridade-itens( :ref="'prioMenu'+props.data.id" )
                                        span(v-for='(v,k) in prioridades_menu(props.data.ie_prioridade_nivel)')
                                            Button.p-button-raised.p-button-rounded.mb-1(
                                                :key="v.label + props.data.id"
                                                type='button'
                                                :label='v.label'
                                                :class="v.color"
                                                v-tooltip.rigth='v.tooltip'
                                                @click="alterarPrioridade(props.data, v);"
                                            )
                Column(headerStyle='width: 10%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mb-1(
                                v-if="['A', 'AG'].includes(props.data.ie_status)"
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click='selectedRow = props.data; dialogEditar = true'
                            )
                            // Button.p-button-raised.p-button-rounded.p-button-success(
                            //     v-if="props.data.ie_status == 'A'"
                            //     v-tooltip.top="'Validar'"
                            //     icon="jam jam-ticket"
                            //     @click='selectedRow = props.data; dialogVisualizar = true'
                            // )
                            Button.p-button-raised.p-button-rounded.ml-1.mb-1(
                                v-if="props.data.ie_status == 'EX'"
                                v-tooltip.top="'Visualizar'"
                                icon="jam jam-eye"
                                @click='selectedRow = props.data; dialogVisualizar = true'
                            )
                            Button.p-button-raised.p-button-rounded.ml-1.mb-1(
                                v-if="props.data.ie_tipo_agenda === 'C' && props.data.ie_habilitado_retorno"
                                v-tooltip.top="'Agendar retorno'"
                                icon="jam jam-undo"
                                @click='selectedRow = props.data; dialogRetorno = true'
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger.ml-1.mb-1(
                                v-if="props.data.ie_force_retorno && usuarioNivel > 3"
                                v-tooltip.top="'Forçar retorno'"
                                icon="jam jam-undo"
                                @click='selectedRow = props.data; forceRetorno = true; dialogRetorno = true;'
                            )
                            span(
                                v-if="props.data.ie_tipo_agenda === 'C' && props.data.ie_status === 'R'"
                                style="vertical-align: top;")
                                ProgressSpinner.waiting-comprovante.ml-1.mb-1(
                                    v-if="waitingComprovanteIds.includes(props.data.id)"
                                    strokeWidth="6")
                                Button.p-button-raised.p-button-rounded.ml-1.mb-1(
                                    v-tooltip.top="'Comprovande de agendamento'"
                                    @click='imprimirComprovanteAgendamento(props.data.id)'
                                    icon="jam jam-printer"
                                    v-else
                                )
                            a.p-button.p-button-raised.p-button-rounded.p-button-icon-only.button-link.mb-1.ml-1(
                                v-if="!['L', 'BO'].includes(props.data.ie_status)"
                                v-tooltip.top="'Dados do Paciente'"
                                :href="`/paciente/salvar/${ props.data.cd_paciente }/`"
                                target="_blank" style="padding:6px;")
                                i(class='jam jam-user')
                            ProgressSpinner.waiting-check.ml-1.mb-1(v-if="checkWaitingIds.includes(props.data.id)" strokeWidth="6" style="vertical-align: top;")
                            span(v-else-if="['A', 'AG', 'RZ'].includes(props.data.ie_status) || (['EX'].includes(props.data.ie_status) && filters.ie_tipo_agenda == 'E')" style="vertical-align: top;")
                                button(
                                    class="p-button-raised p-button-rounded ml-1 mb-1 p-button p-component p-button-icon-only"
                                    style="padding: 4px 5px 1px 6px; height: 33.3px"
                                    v-tooltip.top="`${!props.data.dt_chegada ? 'Fazer' : 'Desfazer'} Check-in`"
                                    @click="openConfirmCheck(props.data, 'checkin')")
                                    CheckinIcon(v-if="!props.data.dt_chegada")
                                    UndoCheckinIcon(v-else)
                                button(
                                    v-if="props.data.dt_chegada"
                                    class="p-button-raised p-button-rounded ml-1 mb-1 p-button p-component p-button-icon-only"
                                    style="padding: 4px 5px 1px 6px; height: 33.3px"
                                    v-tooltip.top="`${!props.data.dt_saida ? 'Fazer' : 'Desfazer'} Check-out`"
                                    @click="openConfirmCheck(props.data, 'checkout')")
                                    CheckoutIcon(v-if="!props.data.dt_saida")
                                    UndoCheckoutIcon(v-else)
                            //Button.p-button-raised.p-button-rounded.mr-1(
                                v-else
                                //:class="`p-button-${props.data.ie_status === 'A' ? 'success': 'danger'}`"
                                v-tooltip.top="`${props.data.ie_status === 'A' ? '' : 'Desfazer'} Checkin`"
                                icon="jam jam-map-marker"
                                @click='openConfirmCheckin(props.data)'
                                )

            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .agendamento-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
                &.l  { background-color: #e1f0f8; }
                &.r  { background-color: #f1e1f8; }
            }
        }
        .filter-data {
            .p-inputtext {
                text-align: center;
            }
        }
        .p-tabview-panels {
            padding: 0 !important;
            border: none !important;
        }
        .p-tabview {
            padding-bottom: 0;
        }
        .p-dropdown-filter-container {
            width: 100%;
        }
        .btn-criarAgendamento, .btn-calendario {
            font-size: 16px; font-weight: 700
        }
        .whatsapp-link {
            margin-left: 8px;
            background-color: #0da64d;
            color: #fff;
            font-weight: 700;
            border-radius: 50%;
            padding: 4px;
            display: inline-block;
            vertical-align: middle;
            width: 28px;
            height: 28px;
            line-height: 24px;
            text-align: center;
            transition: 0.25s;
            &:hover {
                background-color: #0a7d3a;
            }
        }
        .input-ieStatus .p-multiselect-header {
            display: none;
        }
        .status-prioridade {
            display: inline-block;
            font-weight: bold;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            background-color: #007ad9;
            color: #fff;
            border: 1px solid #007ad9;
            transition: 250ms;
            cursor: pointer;
            &:hover { background-color: #116fbf; }
            &.prioridade {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
        }
        .waiting-prioridade {
            max-width: 25px;
            height: auto;
        }
        .waiting-comprovante,
        .waiting-check {
            max-width: 34px;
            height: auto;
        }
        .button-link {
            &:hover { color: #fff; }
        }
        .custom-disabled {
            cursor: default;
            background-color: #c8c8c8 !important;
            border: 1px solid #f4f4f4 !important;
            box-shadow: none !important;
            opacity: 0.5;
        }
        .menu-prioridade {
            display: grid;
            justify-items: center;
            position: relative;
        }
        .menu-prioridade-itens {
            position: absolute;
            z-index: 2;
            display: inline-grid;
            background-color: snow;
            border: 1px solid thistle;
            // clip-path: margin-box;
            border-radius: 25%;
            padding: .7em;
        }
        .fade-enter-active, .fade-leave-active {
            z-index: 2;
            transition: opacity .5s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
        .p-button-urgent {
            color: white;
            background-color: rebeccapurple;
            border: 1px solid slateblue;
            &:hover {
                color: white !important;
                background-color: indigo !important;
                border: 1px solid slateblue !important;
            }
        }
    }
    .p-tooltip {
        z-index: 2
    }
</style>

<script>
    import DataView from 'primevue/dataview'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import Paginator from 'primevue/paginator'
    import Panel from 'primevue/panel'
    import Calendar from 'primevue/calendar'
    import ProgressBar from 'primevue/progressbar'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import InputText from 'primevue/inputtext'
    import Dialog from 'primevue/dialog'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import ProgressSpinner from 'primevue/progressspinner'

    import Visualizar from './Visualizar'
    import Editar from './Editar'
    import ConfirmDialog from "@/components/CustomComponents/ConfirmDialog"

    import moment from 'moment'
    import { Agendamento, Agenda, Especialista, GrupoProcedimentos } from './../../middleware'
    import { pCalendarLocale_ptbr, fixTelefoneFormat } from './../../utils'
	import axios from "axios"
    import CheckinIcon from "@/components/CustomComponents/icons/CheckinIcon";
    import UndoCheckinIcon from "@/components/CustomComponents/icons/UndoCheckinIcon";
    import CheckoutIcon from "@/components/CustomComponents/icons/CheckoutIcon";
    import UndoCheckoutIcon from "@/components/CustomComponents/icons/UndoCheckoutIcon";
    import DialogRetorno from "@/components/Agendamento/DialogRetorno";
    import DialogDetalhesAgenda from "@/components/Agendamento/DialogDetalhesAgenda";
    // import wsConfigs from './../../middleware/configs'

    const _ = require('lodash');

    export default {
        components: {
            DialogDetalhesAgenda, DialogRetorno, ConfirmDialog, Visualizar, Editar,
            UndoCheckinIcon, CheckinIcon, CheckoutIcon, UndoCheckoutIcon, DataView, Panel, ProgressBar, Calendar, InputMask, TabView,
            Tooltip, Paginator, ProgressSpinner, Button, Dropdown, InputText, DataTable, Column, Dialog,
            TabPanel, MultiSelect
        },
        directives: { tooltip: Tooltip },
        created () {

            let usuario = JSON.parse(localStorage.getItem('usuario'))
            this.usuarioNivel = usuario.nivel

            if (['C', 'E', 'P'].includes(this.$route.query.tb)) this.filters.ie_tipo_agenda = this.$route.query.tb
            if (!isNaN(this.$route.query.pg)) this.paginator.page = this.$route.query.pg

            this.getProcedimentos()
            this.applyFilters()
            this.getEspecialidades()
            this.getEspecialistas()
            this.getGruposProcedimentos()
        },
        // watch: {
        //     'filters.dt_inicio': function (val) {
        //         this.filters.dt_fim = val
        //         this.applyFilters()
        //     }
        // },
        data () {
            return {
                list: [],
                totalItens: 0,
                usuarioNivel:1,
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                waiting: false,
                selectedRow: {},
                dialogVisualizar: false,
                dialogEditar: false,
                dialogRetorno: false,
                forceRetorno: false,
                dialogAgenda: false,
                filters: {
                    dt_inicio: moment()._d,
                    dt_fim: moment()._d,
                    ie_status: ['A', 'EX', 'AG'],
                    cd_transacao_financeira: null,
                    especialidade: null,
                    especialista: null,
                    cd_procedimento_estabelecimento: null,
                    ie_tipo_agenda: 'C',
                    nm_paciente: null,
                    ie_unidade: null,
                    grupo_procedimento: null,
                    com_checkin: null,
                },
                options: {
                    especialistas: [],
                    especialidades: [],
                    procedimentos: [],
                    ie_status: [
                        { value: 'A', text: 'Agendado', color: '#faf3dd' },
                        { value: 'L', text: 'Livre', color: '#e1f0f8' },
                        { value: 'EX', text: 'Executado', color: '#e4f8e1' },
                        { value: 'R', text: 'Reservado', color: '#fda2ff' },
                        { value: 'AG', text: 'Aguardando', color: '#34a9eb' },
                        { value: 'BO', text: 'Bloqueado', color: '#fc9595' },
                        { value: 'RZ', text: 'Realizado', color: 'rgb(182 177 215)' }
                    ],
                    ie_unidade: [
                        { value: null, text: 'TODAS' },
                        { value: true, text: 'Unidade' },
                        { value: false, text: 'App' }
                    ],
                    prioridades: {
                        'NORMAL' : { value: '0', label : 'N', tooltip: 'Normal' },
                        'PRIORIDADE': { value: '1', label: 'P', tooltip: 'Prioridade', color: 'p-button-danger' },
                        '80+': { value: '2', label: '80+', tooltip:'Mais de 80 anos', color: 'p-button-urgent'},
                    },
                    grupos_procedimentos: [],
                    com_checkin: [
                        { value: null, text: 'TODOS' },
                        { value: 'true', text: 'Realizado' },
                        { value: 'false', text: 'Não Realizado' },
                    ]
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: 25,
                    count: 0
                },
                order: {
                    field: '',
                    default: '',
                    sortOrder: null
                },
                checkWaitingIds: [],
                checkin: {
                    showDialog: false,
                    isDesfazer: false,
                    horario: {},
                },
                checkout: {
                    showDialog: false,
                    isDesfazer: false,
                    horario: {},
                },
                waitingPrioridadeIds: [],
                waitingComprovanteIds: [],
				invalidNmPaciente: false,
				shakeError: false,
				cancelToken: null,
				params: {},
                getListDebounce: _.debounce(function (params, getFunction) {
					getFunction(params)
				}, 500, { leading: true }),
                btnPrioridadeModel: [],
                showPrioridade: null,
                outsideClickListener: null
            }
        },
        methods: {
            applyFilters (page, force) {
				this.checkNmPaciente()
				if (this.invalidNmPaciente) {
					this.shakeError = true
					setTimeout(() => this.shakeError = false, 300)
					return
				}

                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                let orderField = ''

                if(/^\S*_f$/.test(this.order.field))
                   orderField = this.order.field.replace(/^(\S*)(_f)$/, '$1')

                params.order = orderField
                                    ? `${ this.order.sortOrder == -1 ? '-' : '' }${ orderField }`
                                    : ''

                if (this.$route.query.tb != this.filters.ie_tipo_agenda || this.$route.query.pg != this.paginator.page)
                    this.$router.replace( { query: { tb: this.filters.ie_tipo_agenda, pg: this.paginator.page } } )

                Object.keys(this.filters).forEach((key) => {
                    if (key === 'ie_unidade') {
                        if (this.filters[key] !== null)
                            params[key] = this.filters[key]
                    } else if (this.filters[key]) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                        else if (key === 'ie_status') {
                            params[key] = this.filters[key].toString()
                        } else params[key] = this.filters[key]
                    }
                })
				// Fake loading quando os filtros forem os mesmos
				if (!force && _.isEqual(this.params, params)) {
					if (!this.waiting){
						this.waiting = true
						setTimeout(() => this.waiting = false, 300)
					}
                } else {
                    this.params = Object.assign({}, params)
                    this.waiting = true
					this.getListDebounce(params, this.getList)
                }
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            onSort (ev) {
                switch (ev.sortOrder) {
                    case null:
                        ev.sortField = this.order.field
                        ev.sortOrder = 1
                        break;
                    case -1:
                        ev.sortField = null
                        ev.sortOrder = null
                        break;
                    default:
                        ev.sortOrder = -1
                        break;
                }
                this.order.field = ev.sortField || this.order.default
                this.order.sortOrder = ev.sortOrder
                this.applyFilters()
            },
            checkEmptyField (field) {
                if (! this.filters[field].length) this.applyFilters()
            },
            checkNmPaciente () {
				this.invalidNmPaciente = this.filters.nm_paciente && this.filters.nm_paciente.length < 5
            },
            resetFilers() {
                Object.keys(this.filters).forEach(k => { if (k !== 'ie_tipo_agenda') this.filters[k] = null });
                this.filters.ie_status = ['A', 'EX', 'R', 'AG'];
                this.filters.dt_inicio = moment()._d;
                this.filters.dt_fim = moment()._d;
            },
            clearFilters () {
                Object.keys(this.filters).forEach(k => { if (k !== 'ie_tipo_agenda') this.filters[k] = null })
                this.resetFilers()
                this.applyFilters()
            },
            onTabChange (ev) {
                this.filters.ie_tipo_agenda = ev.tab.$attrs.ieTipoAgenda
                this.filters.cd_procedimento_estabelecimento = null
                this.filters.especialista = null
                this.filters.especialidade = null
                this.paginator.page = 1
                this.applyFilters()
            },
            getList (params) {
                if (!params) return
				if (this.cancelToken) this.cancelToken.cancel()
				this.cancelToken = axios.CancelToken.source()

				this.list = []
				this.waiting = true
                Agendamento.findAllByEstabelecimento(params, this.cancelToken.token).then(response => {
					if (!response) return
                    if (response.status == 200) {
                        let ie_status_obj = {}
                        this.options.ie_status.forEach(e => {
                            if (e.value) ie_status_obj[e.value] = { text: e.text, color: e.color }
                        })
                        this.paginator.count = response.data.count

                        response.data.results.forEach(entry => {
                            entry.dt_agenda_f = moment(entry.dt_agenda).format('DD/MM/YYYY')
                            let aux = entry.hr_agenda.split(':')
                            entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`

                            let statusAux = entry.dt_saida
                                                ? 'RZ'
                                                : entry.dt_chegada
                                                    ? 'AG'
                                                    : entry.ie_status

                            entry.ie_status_f = ie_status_obj[statusAux]?.text
                            entry.status_color = ie_status_obj[statusAux]?.color
                            entry.ie_unidade_f = entry.ie_unidade ? 'Unidade' : 'App'
                            entry.dt_agendamento_f = moment(entry.dt_agendamento).format('DD/MM/YYYY HH:mm')
                            if (entry.dt_chegada)
                                entry.dt_chegada_f = moment(entry.dt_chegada).format('DD/MM/YYYY HH:mm')
                            if (entry.dt_saida)
                                entry.dt_saida_f = moment(entry.dt_saida).format('DD/MM/YYYY HH:mm')
                            entry.ie_prioridade_nivel_f = +this.options.prioridades[entry.ie_prioridade_nivel].value
                            entry.ie_force_retorno = entry.ie_status === 'EX' && entry.ie_tipo_agenda === 'C' &&
                                !entry.ie_habilitado_retorno && !entry.ie_retorno
                            if (entry.nr_telefone_paciente) {
                                entry.nr_telefone_paciente_f = fixTelefoneFormat(entry.nr_telefone_paciente)
                                entry.nr_telefone_paciente_ws = entry.nr_telefone_paciente.match(/\d+/g).join('')
                                if (entry.nr_telefone_paciente_ws.substr(0, 2) === '55') {
                                    entry.nr_telefone_paciente_ws = `${ entry.nr_telefone_paciente_ws }`
                                } else {
                                    entry.nr_telefone_paciente_ws = `55${ entry.nr_telefone_paciente_ws }`
                                }
                            }
                            // entry.cdGuiaEletronica = entry.guiaeletronica_set[0] ? entry.guiaeletronica_set[0] : ''
                        })
                        this.list = response.data.results
                        this.totalItens = response.data.count
                    }
                    this.waiting = false
					this.cancelToken = null
                })
            },
            getEspecialistas () {
                this.options.especialistas = []
                Agenda.getEspecialistasAgendados().then(response => {
                    if (response.status == 200) {
                        let mem = {}
                        response.data.forEach(e => {
                            if (! mem[e.cd_especialista]) {
                                mem[e.cd_especialista] = true
                                this.options.especialistas.push({ text: e.nm_especialista, value: e.cd_especialista })
                            }
                        })
                        this.options.especialistas.sort((a, b) => a.text > b.text ? 1 : -1)
                        this.options.especialistas.unshift({ text: 'TODOS', value: null })
                    }
                })
            },
            getEspecialidades() {
                this.options.especialidades = []
                this.options.especialidades.push({ text: 'TODAS', value: null })
                if (this.filters.especialista == null)
                    Agendamento.getEspecialidades().then(response => {
                        if (response.status == 200) response.data.forEach(e => this.options.especialidades.push({ text: e.nm_especialidade, value: e.id }))
                    })
                else Especialista.getEspecialidades(this.filters.especialista).then(response => {
                    if (response.status == 200) response.data.forEach(e => this.options.especialidades.push({ text: e.nm_especialidade, value: e.id }))
                })
            },
            getProcedimentos() {
                Agendamento.getProcedimentos({ ie_tipo_procedimento: 'E' }).then(response => {
                    if (response.status === 200) {
                        this.options.procedimentos.push({ text: 'TODOS', value: null })
                        response.data.forEach(procedimento => {
                            let nm_procedimento = procedimento.ds_observacao || procedimento.cd_procedimento.nm_procedimento
                            if (procedimento.cd_procedimento)
                                this.options.procedimentos.push({ value: procedimento.id, text: `${ procedimento.cd_procedimento.cd_procedimento } - ${ nm_procedimento }` })
                        })
                    }
                })
            },
            imprimirComprovanteAgendamento(horarioId) {
                this.waitingComprovanteIds.push(horarioId);
                Agendamento.comprovanteAgendamento(horarioId).then(response => {
                    this.waitingComprovanteIds = this.waitingComprovanteIds.filter(id => id !== horarioId);
                    if (response.status === 200) {
                        let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' });
                        let fileURL = URL.createObjectURL(blob);
                        let a = window.open(fileURL);
                        a.print();
                    } else if (response.data?.detail) {
                        this.$toast.error(response.data.detail, { duration: 3000 });
                    }
                })
            },
            alterarPrioridade(horario, prioridade) {
                this.waitingPrioridadeIds.push(horario.id);
                Agendamento.alterarPrioridade(
                    horario.id, {ie_prioridade_nivel: prioridade.value}
                ).then(response => {
                    this.mostrarPrioridades(horario)
                    this.waitingPrioridadeIds = this.waitingPrioridadeIds.filter(id => id !== horario.id );

                    if (response.status === 200) {
                        horario.ie_prioridade_nivel = _.findKey(this.options.prioridades, v => v.label == prioridade.label)
                        this.$toast.success('Alterado com sucesso.', { duration: 3000 })
                    } else if (response.data?.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                    this.applyFilters(null, true)
                })
            },
            onSavedDialogRetorno() {
                this.resetFilers();
                this.filters.nm_paciente = this.selectedRow?.nm_paciente;
                this.applyFilters(null, true);
            },
            openConfirmCheck(horario, check) {
                // let date = moment().format('YYYY-MM-DD')
                // if (horario.ie_status === 'A' && horario.dt_agenda !== date) {
                //     this.$toast.error("O Checkin só é permitido no dia da consulta.", { duration: 3000 })
                //     return
                // }
                this[check].horario = horario;
                this[check].showDialog = true;
                this[check].isDesfazer = check == 'checkin'
                                            ? horario.ie_status_f === 'Aguardando'
                                            : horario.ie_status_f === 'Realizado'
            },
            onConfirmCheck(horario, check, desfazer) {
                this.checkWaitingIds.push(horario.id);
                Agendamento[check](horario.id, desfazer).then(response => {
                    this.checkWaitingIds = this.checkWaitingIds.filter(id => id !== horario.id);
                    if (response.status != 200 && response.data?.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                    this.applyFilters(null, true)
                });
            },
            openDialogAgenda() {
                if (this.filters.especialista) this.dialogAgenda = true;
            },
            openInNewTab(path){
                window.open(path, '_blank', 'noreferrer');
            },
            // nextDay () { this.filters.dt_inicio = moment(this.filters.dt_inicio).add(1, 'days')._d },
            // prevDay () { this.filters.dt_inicio = moment(this.filters.dt_inicio).subtract(1, 'days')._d }
            prioridades_menu (prioridade_atual) {
                return _.filter(this.options.prioridades, (v,k) => k.toLowerCase() != prioridade_atual.toLowerCase())
            },
            mostrarPrioridades(data) {
                this.showPrioridade = this.showPrioridade && this.showPrioridade == data?.id ? null : data?.id
            },
            onOverlayEnter(data) {
                this.bindOutsideClickListener(data)
            },
            onOverlayLeave() {
                this.unbindOutsideClickListener()
            },
            bindOutsideClickListener(data) {
                if (!this.outsideClickListener) {
                    this.outsideClickListener = (event) => {
                        if (this.showPrioridade && this.$refs['prioMenu'+data.id] && !this.$refs['span' + data.id].contains(event.target))
                            this.mostrarPrioridades(data)
                    };
                    document.addEventListener('click', this.outsideClickListener);
                }
            },
            unbindOutsideClickListener() {
                if (this.outsideClickListener) {
                    document.removeEventListener('click', this.outsideClickListener);
                    this.outsideClickListener = null;
                }
            },
            getGruposProcedimentos() {
                GrupoProcedimentos.findAll().then(res => {
                    if(res.status === 200) {
                        this.options.grupos_procedimentos.push({ text: '- Selecione -', value: null })
                        res.data.forEach(gp => {
                            this.options.grupos_procedimentos.push({ value: gp.id, text: gp.ds_descricao });
                        });
                    }
                })
            },
        }
    }
</script>
